import * as React from "react"
import Menu from "../components/menu"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import { subtitle, title, textContainer } from "../styles"
import clsx from "clsx"
import Seo from "../components/seo"

// markup
const DetailTcPage = (props) => {
  return (
    <div>
      <Seo /> 
      <Menu/>
      <div className="max-w-screen-xl mx-5 xl:mx-auto md:grid md:grid-cols-3 md:gap-8">

        <div className="max-w-screen-lg mt-12 text-center col-span-2">
          <StaticImage className="w-4/6 md:w-full"
            src="../images/tc-mockup.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-sm mt-12">
          <h1 className={ title }>
            UX/UI Design - Travel Planning App
          </h1>
          <div className={ clsx(textContainer, "text-justify") }>
            <p className="mb-4">This was the last individual project that was part of the UX/UI Design bootcamp at Ironhack. It was spread onto 2 weeks, one dedicated to primary research, and the second one dedicated to prototyping, testing, iterating and delivering. Similarly to the other projects, I used the Design Thinking Framework throughout the design process.</p>
            <p className="mb-4"> If you have you ever felt overwhelmed when preparing a trip, because you were not sure about what to pack, what to see/do once there, or because you couldn’t remember if all the bookings were sorted out, this app is for you. TravelCapsule, helps you cope with the stress of organizing your trips. The app allows you to centralize all your travel information in one place and it gives you tips and recommendations to best prepare your experience abroad. It also sends reminders when your journey is incomplete (missing accommodation, booking, etc.).</p>
          </div>

          <div className={ textContainer }>
            <h2 className = { subtitle }> Client </h2>
            <p className="mb-4">Ironhack</p>
            <h2 className = { subtitle }> Type </h2>
            <p className="mb-4">UX/UI Design</p>
            <h2 className = { subtitle }> Documentation </h2>
            <a className="block mb-2 underline" href="https://medium.com/@charlottebotermans/travelcapsule-an-app-that-centralizes-all-your-travel-information-in-one-place-available-89c42b0a9b6a" target="_blank" rel="noreferrer"> Medium report </a>
            <h2 className = { subtitle }> Year </h2>
            <p className="mb-4">2019</p>
          </div>
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/tc-img1.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3 ">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/tc-img2.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

      </div>
      <Footer/>
    </div>
     
  
  )
}


export default DetailTcPage